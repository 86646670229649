<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                    <v-card-title>
                        <div class="primary--text search-title">{{ $t('forms.WithdrawVoucherForm.title') }}</div>
                    </v-card-title>

                    <v-card-text>
                        <div v-if="localLoading" class="d-flex mb-4 justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <SearchCustomerInput
                            v-else
                            ref="document_field"
                            fieldName="customer_document"
                            :document="localDocumentValue"
                            @changed-valididy="value => isCustomerValid = value"
                            @selected-customer="handleSelectedCustomer"
                            :value.sync="value"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            
            <template v-if="isCustomerValid && selectedCustomer">
                <v-col cols="12" sm="12" md="4">
                    <CustomerCard :customer="selectedCustomer" class="my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card"/>
                </v-col>

                <v-col cols="12" sm="12" md="8" :align-self="selectedCustomer ? null : 'center'">
                    <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                        <v-card-text>
                            <span class="mt-1 primary--text section-title">{{ $t('forms.WithdrawVoucherForm.subtitle') }}</span>

                            <SearchVoucherInput
                                ref="code_field"
                                :value.sync="value"
                                :code="localCodeValue"
                                :acceptWithdrawnVoucher="false"
                                @changed-valididy="value => isVoucherValid = value"
                                @selected-voucher="value => selectedVoucher = value && !value.withdrawn_at ? value : null"
                                class="mt-6"
                            />

                            <template v-if="isVoucherValid && selectedVoucher">
                                <v-col cols="12">
                                    <PrizeCard :prize="selectedVoucher.prize"></PrizeCard>
                                </v-col>

                                <slot name="actions"></slot>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import SearchCustomerInput from '@/components/inputs/SearchCustomerInput'
import SearchVoucherInput  from '@/components/inputs/SearchVoucherInput'
import PrizeCard           from '@/shared/components/cards/PrizeCard'
import CustomerCard        from '@/shared/components/cards/CustomerCard.vue'
import BaseModel           from '@/models/BaseModel'
import FormMixin           from '@/mixins/FormMixin'
import Customer            from '@/models/Customer'

export default {
    name: 'GC_CommonForm',
    mixins: [ FormMixin(BaseModel, 'document_field') ],
    components: {
        SearchCustomerInput,
        SearchVoucherInput,
        CustomerCard,
        PrizeCard,
    },
    props: {
        id      : String,
        document: String,
        code    : String,
    },
    data: vm => ({
        selectedCustomer  : null,
        selectedVoucher   : null,
        localDocumentValue: vm.document,
        localCodeValue    : vm.code,
        localLoading      : false,
        isCustomerValid   : false,
        isVoucherValid    : false,
    }),
    async created() {
        if (this.id && !this.document)
            await this.fetchData()
    },
    mounted() {
        this.value.touch()
    },
    methods: {
        async fetchData() {
            this.localLoading = true

            let errType = ''
            let res = await (new Customer({ id: this.id })).get()
                .catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!res || !res.data || !res.status == 200))
                errType = 'error'

            if (errType) {
                this.localLoading = false

                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`forms.WithdrawVoucherForm.alerts.${errType}`), 'error', resolve)
                })
                return
            }
                
            this.localDocumentValue = (new Customer(res.data)).document
            this.localLoading       = false
        }, 
        handleSelectedCustomer(value) {
            this.selectedCustomer = value

            if (value && value.id != this.id)
                this.$router.push({ name: 'vouchers.withdraw', params: { id: value.id, document: value.document } })
        }
    },
}
</script>

<style lang="scss" scoped>
.search-title {
    font-size: 16px;
    font-weight: bold;
}

.section-title {
    font-size: 16px !important;
    font-weight: bold !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}
</style>