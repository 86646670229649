var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "ml-md-3 pl-5 pl-md-12 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.vouchers.Withdraw.title")))
      ]),
      _c("WithdrawVoucherForm", {
        ref: "form",
        staticClass: "mb-4 mx-3",
        attrs: {
          code: _vm.code,
          id: _vm.id,
          document: _vm.document,
          isFormValid: _vm.isFormValid
        },
        on: {
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          submit: function($event) {
            return _vm.submit(_vm.voucher)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c("div", { staticClass: "mt-6 pb-2" }, [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button",
                              attrs: {
                                disabled: _vm.loading,
                                label: _vm.$t("actions.back"),
                                text: "",
                                color: "gray"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("actions.back")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button",
                              attrs: {
                                depressed: "",
                                type: "submit",
                                loading: _vm.loading,
                                disabled: !_vm.isFormValid,
                                label: _vm.$t(
                                  "views.vouchers.Withdraw.withdraw"
                                ),
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("views.vouchers.Withdraw.withdraw")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "action-button-mobile",
                              attrs: {
                                depressed: "",
                                type: "submit",
                                loading: _vm.loading,
                                disabled: !_vm.isFormValid,
                                label: _vm.$t(
                                  "views.vouchers.Withdraw.withdraw"
                                ),
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("views.vouchers.Withdraw.withdraw")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 action-button-mobile",
                              attrs: {
                                disabled: _vm.loading,
                                label: _vm.$t("actions.back"),
                                text: "",
                                color: "gray"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("actions.back")) + " ")]
                          )
                        ],
                        1
                      )
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.voucher,
          callback: function($$v) {
            _vm.voucher = $$v
          },
          expression: "voucher"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }