<template>
    <div>
        <span class="ml-md-3 pl-5 pl-md-12 section-title">{{ $t('views.vouchers.Withdraw.title') }}</span>
        <WithdrawVoucherForm
            ref="form"
            v-model="voucher"
            :code="code"
            :id="id"
            :document="document"
            :isFormValid.sync="isFormValid"
            @submit="submit(voucher)"
            class="mb-4 mx-3"
        >
            <template #actions>
                <div class="mt-6 pb-2">
                    <!-- Versão desktop -->
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-end">
                        <v-btn @click="$router.go(-1)" class="action-button" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>

                        <v-btn depressed class="action-button" type="submit" :loading="loading" :disabled="!isFormValid" :label="$t('views.vouchers.Withdraw.withdraw')" color="primary">
                            {{ $t('views.vouchers.Withdraw.withdraw') }}
                        </v-btn>
                    </div>
                    <!-- Versão mobile -->
                    <div v-else>
                        <v-btn depressed class="action-button-mobile" type="submit" :loading="loading" :disabled="!isFormValid" :label="$t('views.vouchers.Withdraw.withdraw')" color="primary">
                            {{ $t('views.vouchers.Withdraw.withdraw') }}
                        </v-btn>

                        <v-btn @click="$router.go(-1)" class="mt-2 action-button-mobile" :disabled="loading" :label="$t('actions.back')" text color="gray">
                            {{ $t('actions.back') }}
                        </v-btn>
                    </div>
                </div>
            </template>
        </WithdrawVoucherForm>
    </div>
</template>

<script>
import WithdrawVoucherForm from '@/components/forms/WithdrawVoucherForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Voucher from '@/models/Voucher'

export default {
    name: 'WithdrawVoucherView',
    mixins: [ HasErrorHandlerMixin ],
    components: { WithdrawVoucherForm },
    props: {
        id      : String,
        document: String,
        code    : String,
    },
    data: vm => ({
        voucher: new Voucher,
        loading: false,

        isFormValid: false,
    }),
    methods: {
        async submit(voucher) {
            if (!this.isFormValid || !voucher)
                return

            this.loading = true
            let errType = ''
            let res = await voucher.withdraw()
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t('views.vouchers.Withdraw.success'), 'success')
            this.$router.push({ name: 'customers.details', params: { id: voucher.customer_id, document: voucher.customer_document } })
        },
        preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')

            if (e.message == 'Network Error') {
                this.$bus.$emit('alert', this.$t('views.vouchers.Withdraw.errors.network_error'), 'error')
                return
            }

            if (status == 422) {
                let errorType = this.$lodash.get(e, 'response.data.type')

                let errorMessages = []
                this.$bus.$emit('alert', this.$t('views.vouchers.Withdraw.errors.generic'), 'error')
                
                return
            }

            this.errorHandler(e)
        },
    },
}
</script>

<style lang="scss" scoped>
.action-button {
    width: 140px;
}

.action-button-mobile {
    width: 100%;
}
</style>