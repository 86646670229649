var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "12" } },
        [
          _c("cp-text-field", {
            ref: "code_field",
            attrs: {
              autofocus: "",
              "error-messages": _vm.getErrorMessages("code"),
              hint: _vm.$t("components.inputs.SearchVoucherInput.code.hint"),
              label: _vm.$t("components.inputs.SearchVoucherInput.code.label"),
              placeholder: _vm.$t(
                "components.inputs.SearchVoucherInput.code.placeholder"
              ),
              "success-messages": !!_vm.selectedVoucher
                ? [_vm.$t("components.inputs.SearchVoucherInput.code.success")]
                : [],
              success: !!_vm.selectedVoucher,
              loading: _vm.loadingVoucher,
              disabled: _vm.loading,
              value: _vm.code,
              rules: _vm.rules,
              solo: "",
              maxlength: 191
            },
            on: {
              input: function($event) {
                _vm.clearErrorMessages("code")
                _vm.searchVoucherByCode($event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "py-0" },
        [
          _vm.withdrawnVoucher
            ? _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.inputs.SearchVoucherInput.withdrawn_voucher"
                      )
                    ) +
                    " "
                )
              ])
            : _vm.voucherNotFound
            ? _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.inputs.SearchVoucherInput.voucher_not_found"
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }