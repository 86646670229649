<template>
    <v-row>
        <!-- Seleção de código -->
        <v-col cols="12" class="pt-0">
            <cp-text-field
                autofocus
                ref="code_field"
                :error-messages="getErrorMessages('code')"
                @input="clearErrorMessages('code'); searchVoucherByCode($event)"
                :hint="$t('components.inputs.SearchVoucherInput.code.hint')"
                :label="$t('components.inputs.SearchVoucherInput.code.label')"
                :placeholder="$t('components.inputs.SearchVoucherInput.code.placeholder')"
                :success-messages="!!selectedVoucher ? [$t('components.inputs.SearchVoucherInput.code.success')] : []"
                :success="!!selectedVoucher"
                :loading="loadingVoucher"
                :disabled="loading"
                :value="code"
                :rules="rules"
                solo
                :maxlength="191"
            >
            </cp-text-field>
        </v-col>
        <v-col class="py-0">
            <v-alert v-if="withdrawnVoucher" type="error">
                {{ $t('components.inputs.SearchVoucherInput.withdrawn_voucher') }}
            </v-alert>
            <v-alert v-else-if="voucherNotFound" type="error">
                {{ $t('components.inputs.SearchVoucherInput.voucher_not_found') }}
            </v-alert>
        </v-col>
    </v-row>    
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { required, string, length } from '@/utils/validation'
import FormMixin from '@/mixins/FormMixin'
import BaseModel from '@/models/BaseModel'
import Voucher from '@/models/Voucher'

export default {
    name: 'SearchVoucherInput',
    mixins: [ FormMixin(BaseModel, 'code_field'), HasErrorHandlerMixin ],
    props: {
        code: {
            required: true,
        },
        value: {
            required: true,
        },
        acceptWithdrawnVoucher: {
            type: Boolean,
            default: true,
        },
    },
    data: (vm) => ({
        // Validação no modelo não funciona no segundo campo
        rules: [ required.and(string).and(length(vm.$constants.getConstant('VOUCHER_MIN_CODE_LENGTH'), 191)) ],

        loadingVoucher  : false,
        selectedVoucher : null,

        withdrawnVoucher: false,
        voucherNotFound : false,
    }),    
    methods: {
        async searchVoucherByCode(value) {
            this.selectedVoucher  = null
            this.withdrawnVoucher = false
            this.voucherNotFound  = false
            value                 = typeof value == 'string' ? value.toUpperCase() : value
            this.value.code       = value
            let codeModelHasError = !!this.$lodash.get(this.value, 'errors.code.length')
            let valueTooShort     = !value || value.length < this.$constants.getConstant('VOUCHER_MIN_CODE_LENGTH')

            if (this.loadingVoucher || valueTooShort || codeModelHasError) {
                this.$emit('changed-valididy', false)
                this.$emit('selected-voucher', null)
                return
            }

            this.loadingVoucher = true

            let voucher = await Voucher.getByCodeAndDocument(this.value.code, this.value.customer_document)
                .catch(this.preErrorHandler)

            this.loadingVoucher = false

            if (!voucher) {
                this.$emit('selected-voucher', null)
                return
            }

            if (!this.acceptWithdrawnVoucher && voucher.withdrawn_at) {
                this.withdrawnVoucher = true
                return
            }

            this.selectedVoucher = voucher

            this.$emit('changed-valididy', true)
            this.$emit('selected-voucher', this.selectedVoucher)
        },
        preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')
            if (status === 404) {
                this.voucherNotFound = true
                return
            }

            this.errorHandler(e)
        },
    },
}
</script>