var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0"
                },
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "primary--text search-title" }, [
                      _vm._v(_vm._s(_vm.$t("forms.WithdrawVoucherForm.title")))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.localLoading
                        ? _c(
                            "div",
                            { staticClass: "d-flex mb-4 justify-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" }
                              })
                            ],
                            1
                          )
                        : _c("SearchCustomerInput", {
                            ref: "document_field",
                            attrs: {
                              fieldName: "customer_document",
                              document: _vm.localDocumentValue,
                              value: _vm.value
                            },
                            on: {
                              "changed-valididy": function(value) {
                                return (_vm.isCustomerValid = value)
                              },
                              "selected-customer": _vm.handleSelectedCustomer,
                              "update:value": function($event) {
                                _vm.value = $event
                              }
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isCustomerValid && _vm.selectedCustomer
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12", md: "4" } },
                  [
                    _c("CustomerCard", {
                      staticClass:
                        "my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card",
                      attrs: { customer: _vm.selectedCustomer }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12",
                      sm: "12",
                      md: "8",
                      "align-self": _vm.selectedCustomer ? null : "center"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0"
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mt-1 primary--text section-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("forms.WithdrawVoucherForm.subtitle")
                                  )
                                )
                              ]
                            ),
                            _c("SearchVoucherInput", {
                              ref: "code_field",
                              staticClass: "mt-6",
                              attrs: {
                                value: _vm.value,
                                code: _vm.localCodeValue,
                                acceptWithdrawnVoucher: false
                              },
                              on: {
                                "update:value": function($event) {
                                  _vm.value = $event
                                },
                                "changed-valididy": function(value) {
                                  return (_vm.isVoucherValid = value)
                                },
                                "selected-voucher": function(value) {
                                  return (_vm.selectedVoucher =
                                    value && !value.withdrawn_at ? value : null)
                                }
                              }
                            }),
                            _vm.isVoucherValid && _vm.selectedVoucher
                              ? [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("PrizeCard", {
                                        attrs: {
                                          prize: _vm.selectedVoucher.prize
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._t("actions")
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }